import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './index.module.css'

export default () => (
  <StaticQuery
    query={graphql`
      query ExperienceQuery {
        site {
          siteMetadata {
            home {
              location
              email
              website
            }
          }
        }
      }
    `}
    render={data => (
      <div styleName='wrapper'>
        
      </div>
    )}
  />
)
