import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './index.module.css'

export default () => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        site {
          siteMetadata {
            home {
              location
              email
              website
            }
          }
        }
      }
    `}
    render={data => (
      <div styleName='wrapper'>
        <h2>About Me</h2>
        <p>Just another Software Engineer who likes his <span styleName='hlight'>JavaScript</span>, <span styleName='hlight'>Node</span>, <span styleName='hlight'>Dart</span>, <span styleName='hlight'>Flutter</span> and whatever else he's doing to pass the time.</p>
      </div>
    )}
  />
)
