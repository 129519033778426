import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
// import PostLink from '../components/post-link'
import About from '../components/about'
import Experience from '../components/experience'

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges }
  }
}) => {
  // const Posts = edges
  //   .filter(edge => !!edge.node.frontmatter.date)
  //   .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name='description' content={site.siteMetadata.description} />
        {!site.siteMetadata.w3l_dom_key ? null : <meta name='w3l-domain-verification' content={site.siteMetadata.w3l_dom_key} />}
      </Helmet>

      <About />

      <Experience />

      {/* <section>
        <h2>Blog Posts</h2>
        <div className="grids">
          {Posts}
        </div>
      </section> */}
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
        w3l_dom_key
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
